@charset "UTF-8";
/********************
*** Icon font details ***
*********************/
#content {
  padding-top: 20px; }

.font-icon-list {
  padding: 20px 0px 0px 0px;
  margin-bottom: 20px; }

.font-icon-list:hover {
  cursor: pointer; }

.font-icon-detail {
  text-align: center; }

.font-icon-detail span:first-child {
  display: inline-block;
  transition: color 150ms linear, background 150ms linear, font-size 150ms linear, width 150ms;
  padding: 10px 0px;
  width: 70px;
  font-size: 48px;
  color: #1a1a1a;
  border: 1px solid #eaeaea;
  border-radius: 3px; }

.font-icon-name {
  font-size: 13px;
  margin-top: 15px;
  display: block;
  text-align: center;
  width: 100%;
  padding: 0;
  border: 0; }

.font-icon-name:focus, .font-icon-name:active {
  outline: none; }

.font-icon-code {
  max-height: 0;
  overflow: hidden;
  text-align: center;
  opacity: 0;
  transition: max-height 200ms linear, opacity 200ms linear; }

.font-icon-code.show {
  max-height: 200px;
  opacity: 1; }

.code-value, .unicode, .unicode-text {
  background: none;
  text-align: center;
  border: none;
  color: #a0a0a0; }

.code-value {
  display: block;
  width: 100%; }

.unicode, .unicode-text {
  color: #a0a0a0; }

.unicode {
  float: left;
  font-family: "Pe-icon-7-stroke";
  text-align: right;
  width: 38%;
  padding-right: 5px; }

.unicode-text {
  text-align: left;
  float: left;
  display: inline-block;
  width: 100px;
  border: none; }

.unicode-text:focus,
.unicode-text:active {
  outline: none; }

.font-icon-list:hover .font-icon-name,
.font-icon-list:hover .code-value,
.font-icon-list:hover .unicode,
.font-icon-list:hover .unicode-text,
.font-icon-detail.zeroclipboard-is-hover .font-icon-name,
.font-icon-detail.zeroclipboard-is-hover + .font-icon-code .code-value,
.font-icon-detail.zeroclipboard-is-hover + .font-icon-code .unicode,
.font-icon-detail.zeroclipboard-is-hover + .font-icon-code .unicode-text {
  cursor: text;
  color: #1a1a1a; }

.font-icon-list:hover .font-icon-detail span:first-child,
.font-icon-detail.zeroclipboard-is-hover span:first-child {
  color: #fff;
  background: #1a1a1a; }

.size16 .font-icon-detail span:first-child {
  width: 38px; }

.size32 .font-icon-detail span:first-child {
  width: 54px; }

.size64 .font-icon-detail span:first-child {
  width: 86px; }

.size80 .font-icon-detail span:first-child {
  width: 102px; }

.size96 .font-icon-detail span:first-child {
  width: 118px; }

.size112 .font-icon-detail span:first-child {
  width: 134px; }

.size128 .font-icon-detail span:first-child {
  width: 150px; }

.font-icon-name::-moz-selection,
.code-value::-moz-selection,
.unicode::-moz-selection,
.unicode-text::-moz-selection,
.font-icon-name::-moz-selection {
  background: #aadce2;
  color: #1a1a1a; }

.font-icon-name::selection,
.code-value::selection,
.unicode::selection,
.unicode-text::selection,
.font-icon-name::selection {
  background: #aadce2;
  color: #1a1a1a; }

.font-icon-name::-moz-selection,
.code-value::-moz-selection,
.unicode::-moz-selection,
.unicode-text::-moz-selection,
.font-icon-name::-moz-selection {
  background: #aadce2;
  color: #1a1a1a; }

.dark .font-icon-name::-moz-selection,
.dark .code-value::-moz-selection,
.dark .unicode::-moz-selection,
.dark .unicode-text::-moz-selection,
.dark .font-icon-name::-moz-selection {
  color: white; }

.dark .font-icon-name::selection,
.dark .code-value::selection,
.dark .unicode::selection,
.dark .unicode-text::selection,
.dark .font-icon-name::selection {
  color: white; }

.dark .font-icon-name::-moz-selection,
.dark .code-value::-moz-selection,
.dark .unicode::-moz-selection,
.dark .unicode-text::-moz-selection,
.dark .font-icon-name::-moz-selection {
  color: white; }

/********************
*** Download box ***
*********************/
#download_box {
  background: #fff;
  padding: 44px 0px 34px 0px;
  text-align: center;
  border-top: 1px solid #eaeaea; }
  #download_box .download {
    height: 72px;
    line-height: 72px;
    padding: 0px 38px 0px 44px;
    font-size: 18px; }
  #download_box span {
    margin-left: 15px;
    vertical-align: middle;
    font-size: 34px; }

/********************
*** Footer ***
*********************/
.main-footer {
  font-size: 13px;
  text-align: center;
  padding: 30px 0px;
  margin-top: 60px;
  background-color: #1a1a1a; }

/********************
*** Dark style ***
*********************/
body.dark {
  background: #1a1a1a; }

.dark .font_options {
  background: #171717;
  border-top: 1px solid #282828;
  border-bottom: 1px solid #282828; }

.dark .navbar-nav > li {
  border-left: 1px solid #282828; }

.dark .navbar-nav > li.size_select {
  border-right: 1px solid #282828; }

.dark #nav.affix {
  background: rgba(23, 23, 23, 0.8); }

.dark #content {
  background: #1a1a1a; }

.dark strong {
  color: #fff; }

.dark .checkbox + label:before {
  background: #323232;
  border: 1px solid #000;
  color: #a0a0a0; }

.dark .font-icon-detail span:first-child {
  border: 1px solid #2b2b2b;
  color: #fff; }

.dark .font-icon-list, .dark .code-value {
  color: #a0a0a0; }

.dark .font-icon-name {
  background-color: #1a1a1a; }

.dark .font-icon-list:hover .font-icon-detail span:first-child,
.dark .font-icon-detail.zeroclipboard-is-hover span:first-child {
  color: #1a1a1a;
  background: #fff; }

.dark .font-icon-list:hover .font-icon-name,
.dark .font-icon-list:hover .code-value,
.dark .font-icon-list:hover .unicode,
.dark .font-icon-list:hover .unicode-text,
.dark .font-icon-detail.zeroclipboard-is-hover .font-icon-name,
.dark .font-icon-detail.zeroclipboard-is-hover + .font-icon-code .code-value,
.dark .font-icon-detail.zeroclipboard-is-hover + .font-icon-code .unicode,
.dark .font-icon-detail.zeroclipboard-is-hover + .font-icon-code .unicode-text {
  color: #fff; }

/*******************************
*** RESPONSIVE Media Queries ***
********************************/
@media (max-width: 767px) {
  #showcase h2 {
    font-size: 20px;
    padding-bottom: 20px;
    line-height: 1.3em; }

  .navbar-collapse.in {
    overflow: visible; }

  .navbar-collapse.in,
  .navbar-collapse.collapsing {
    box-shadow: inset 0 1px 0 #eaeaea; }

  .bootstrap-select.btn-group .dropdown-menu {
    position: absolute; }

  .navbar-header {
    padding: 0px 20px; }

  .bg-switch {
    position: absolute;
    top: 10px;
    right: 40px; }

  .navbar-nav > li.size_select,
  .dark .navbar-nav > li.size_select {
    border: none;
    position: absolute;
    top: 58px;
    right: 5px; }

  .navbar-nav > li,
  .dark .navbar-nav > li {
    border: none; }

  .bootstrap-select.open {
    border: 1px solid #eaeaea;
    border-bottom: none; }

  .bootstrap-select.btn-group .dropdown-menu {
    min-width: 133px; }

  .bootstrap-select > .btn {
    padding: 0px 30px 0px 0px; }

  .unicode {
    width: 47%; }

  /* Dark style*/
  .dark .navbar-collapse.in,
  .dark .navbar-collapse.collapsing {
    box-shadow: inset 0 1px 0 #282828; }

  .dark .navbar-toggle {
    color: #fff; }

  .dark .bootstrap-select.btn-group .dropdown-menu {
    min-width: 134px;
    margin-left: -1px; }

  .dark .btn-group.open .dropdown-toggle,
  .dark .btn-group.open .dropdown-toggle:focus {
    border: none; } }
@media (max-width: 480px) {
  #showcase h1 {
    font-size: 56px; }

  .unicode {
    width: 45%; } }

/*# sourceMappingURL=style.css.map */
